<template>
    <div class="FormBox">
        <van-form  @submit="onSubmit" :show-error='false' ref="Form" :scroll-to-error='true'>
            <div class="EventBox">
                <h4 class="FormBoxTitle">预约信息</h4>
                <van-cell-group>
                    <van-field @input="$emit('ModelChange',TestValue)" required v-model="TestValue.LA_ProjectName" label="实验项目名称" placeholder="实验项目名称"  :rules="[{ required: true, message: '实验项目名称' }]" />
                    <van-field is-link required :value="TestValue.LA_IName"  readonly @click="LabClick" clickable label="实验室名称" placeholder="实验室名称"  :rules="[{ required: true, message: '实验室名称',trigger:'onChange' }]" />
                    
                    <van-field :border='false' @input="$emit('ModelChange',TestValue)" required v-model="TestValue.LA_Instructor" label="指导老师姓名" placeholder="指导老师姓名"  :rules="[{ required: true, message: '指导老师姓名' }]" />
                    <!-- <van-field  :border='false'  :value="TestValue.LA_Time | Datetime('yyyy-mm-dd') " name="使用时间" readonly @click="OpenTime" clickable label="使用时间" placeholder="使用时间"  :rules="[{ required: true, message: '使用时间',trigger:'onChange' }]" /> -->
                    <lab-calendar :laboratoryId='TestValue.LA_IID' @input="$emit('ModelChange',TestValue)" v-model="TestValue.TimeList" label="使用时间" placeholder="使用时间" :rules="[{ required: true, message: '使用时间',trigger:'onChange' }]"></lab-calendar>
                    
                    <van-field required name="radio" label="需要危险品">
                        <template #input>
                            <van-radio-group v-model="TestValue.LA_DangerousGoods" direction="horizontal" @change="ChageDangerou">
                            <van-radio :name="0">不需要</van-radio>
                            <van-radio :name="1">需要</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>

                    <van-field v-if="TestValue.LA_DangerousGoods == 1" is-link required  :value='TestValue.LA_ApprovalName'  readonly @click="ApprovaIsShow = true" clickable label="危险品审批单" placeholder="危险品审批单(项目名称)"  :rules="[{ required: true, message: '实验室名称',trigger:'onChange' }]" />
                </van-cell-group>

                <div class="dangerBox" v-if="TestValue.LA_DangerousGoods == 1 && TestValue.LA_DAPhontos">
                    <h4 class="van-hairline--bottom">危险品前置审批单签字（盖章）图片</h4>
                    <van-image class="ImgBox" fit="cover" :src="TestValue.LA_DAPhontos" radius='8px'/>
                </div>

                <div class="BoxRow">
                    <h4>实验人员信息（包括：实验学生姓名、学号、人数）</h4>
                    <van-field @input="$emit('ModelChange',TestValue)" v-model="TestValue.LA_PersonnelInformation"  placeholder="实验人员信息（包括：实验学生姓名、学号、人数）" 
                    class=""
                     type="textarea"
                     autosize
                     rows="4"
                    :rules="[{ required: true, message: '实验人员信息（包括：实验学生姓名、学号、人数）' }]"
                    />
                </div>
                <div class="BoxRow">
                    <h4>对所需环境的要求（如：面积、温湿度、通风要求等）</h4>
                    <van-field @input="$emit('ModelChange',TestValue)" v-model="TestValue.LA_EnvironmentalRequirements"  placeholder="对所需环境的要求（如：面积、温湿度、通风要求等）" 
                    class=""
                     type="textarea"
                     autosize
                     rows="4"
                    :rules="[{ required: true, message: '对所需环境的要求（如：面积、温湿度、通风要求等）' }]"
                    />
                </div>

            </div>
            
        </van-form>
        <div class="InfButtom van-hairline--top">
            <van-button  type="primary" block @click="NextClick">下一步</van-button>
        </div>
        
        <!-- 选择实验室使用使劲 -->
        <!-- <van-popup v-model="UseTime" position="bottom">
            <van-datetime-picker  :formatter="formatter"  @cancel="UseTime = false"  @confirm="UseTimeConfirm" v-model="currentDate" type="date" title="实验室使用时间"/>
        </van-popup> -->

        <!-- <van-calendar ref="calendar" confirm-disabled-text='请选择实验室预约时间' :color="'#1989fa'" :default-date='null' title="实验室使用时间" v-model='UseTime'  :min-date="minDate" :max-date="maxDate"  @confirm="onConfirmCalendar" :formatter="formatters"/> -->

        <van-popup v-model="LabrIsShow" position="bottom" >
            <van-picker title="实验室名称" show-toolbar value-key='I_Name' :columns="columns" @confirm="onConfirm" @cancel="LabrIsShow = false;" />
        </van-popup>

        <van-popup v-model="ApprovaIsShow" position="bottom" >
            <van-picker title="危险品前置审批单" show-toolbar value-key='DA_ProjectName' :columns="ApplicationList" @confirm="ApprovaonConfirm" @cancel="ApprovaIsShow = false;" />
        </van-popup>


    </div>
</template>
<script>
import { Form,CellGroup,Field,Popup,DatetimePicker,Button ,Icon , RadioGroup, Radio ,Picker , Image as VanImage,Calendar,Toast  } from 'vant';
import {Api_YyMAALaboratoryAppointment_ReservationLaboratoryList,Api_YyMAALaboratoryAppointment_DangerousApplicationList,Api_YyMAALaboratoryAppointment_GetYYTime} from '@/api/laboratory'

import labCalendar from '@/publicComponents/labCalendar'
export default {
    model:{
        prop: 'TestValue',
        event: 'ModelChange'
    },
    props:{
        TestValue:{
            type:Object,
        }
    },
    components:{
        [Form.name]: Form,
        [CellGroup.name]: CellGroup,
        [Field.name]: Field,
        [Popup.name]: Popup,
        [DatetimePicker.name]: DatetimePicker,
        [Button.name]: Button,
        [Icon.name]: Icon,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [Picker.name]: Picker,
        [VanImage.name]: VanImage,
        [Calendar.name]: Calendar,
        [Toast.name]: Toast,
        [labCalendar.name]: labCalendar,
    },
    data() {
        return {
       
           minDate: new Date(),
           maxDate: new Date(''+new Date().getFullYear()+'/'+(new Date().getMonth()+2)+'/'+new Date().getDate()+''),
           ApprovaIsShow:false,
           LabrIsShow:false,
           UseTime:false,
           currentDate:new Date(),
           columns:[],
           ApplicationList:[],
           LabDisdArry:[]
        }
    },
    created(){
       
        Api_YyMAALaboratoryAppointment_ReservationLaboratoryList().then(res=>{
            this.columns = res
            if(this.$route.query.I_ID){
                res.forEach(element => {
                    if(element.I_ID == this.$route.query.I_ID) {
                        this.TestValue.LA_IName = element.I_Name
                        this.TestValue.LA_IID = element.I_ID
                    }
                });
            }
        })
         //获取微信前置审批单下拉
        if(this.TestValue.LA_DangerousGoods == 1 || this.$route.query.DA_ID){
            
            if(this.$route.query.DA_ID) {
                this.TestValue.LA_DAID = this.$route.query.DA_ID
                this.TestValue.LA_DangerousGoods = 1
                Api_YyMAALaboratoryAppointment_DangerousApplicationList().then(res=>{
                    this.ApplicationList = res
                    res.forEach(element => {
                        if(element.DA_ID == this.$route.query.DA_ID) {

                            this.TestValue.LA_DAPhontos = element.DA_AuditPhotos
                            this.TestValue.LA_ProjectName = this.TestValue.LA_ApprovalName = element.DA_ProjectName
                        }
                    });
                })
            }
            if(this.TestValue.LA_DangerousGoods = 1) {
                 Api_YyMAALaboratoryAppointment_DangerousApplicationList().then(res=>{
                    this.ApplicationList = res
                   
                })
            }
        }
    },
    methods:{
        LabClick(){
            
            this.LabrIsShow = true
        },
        OpenTime(){
            if(this.TestValue.LA_IID === '' || this.TestValue.LA_IID === null || this.TestValue.LA_IID === undefined){
                this.$toast('请选择预约实验室名称')
                return
            }
            Api_YyMAALaboratoryAppointment_GetYYTime({
                IID:this.TestValue.LA_IID
            }).then(res=>{
                // console.log(res)
                res.forEach(element => {
                    this.LabDisdArry.push( new Date(element).getTime())
                });
                
               
                this.UseTime = true
            })
           
        },
        
        // onConfirmCalendar(time){
          
        //     // console.log(data)
        //     this.TestValue.LA_Time = {}
            
        //     this.$emit('ModelChange',this.TestValue)
        //      console.log(this.$refs.calendar.init())
        //     this.UseTime = false;
             
        // },
        formatters(day){
          

            if(day.type != "disabled"){
               
                const TimeDisabled = new Date(this.$options.filters['Datetime'](day.date,'yyyy-mm-dd')).getTime()
                 
                if(this.LabDisdArry.indexOf(TimeDisabled) >=0) {
                   day.type = 'disabled'
                   day.bottomInfo= '已预约'
                }else {
                    //  day.bottomInfo= '可预约'
                }
            }
            return day;
        },
        ApprovaonConfirm(row){
            this.TestValue.LA_DAID = row.DA_ID
            this.TestValue.LA_DAPhontos = row.DA_AuditPhotos
            this.TestValue.LA_ApprovalName = row.DA_ProjectName
            
            this.$emit('ModelChange',this.TestValue)
            this.ApprovaIsShow = false
        },
        ChageDangerou(value){
            
            if(value == 1 && this.ApplicationList.length < 1){
                Api_YyMAALaboratoryAppointment_DangerousApplicationList().then(res=>{
                    
                    this.ApplicationList = res
                })
            }
            this.$emit('ModelChange',this.TestValue)
           
        },
        formatter(type, val) {
            if (type === 'year') {
                return `${val}年`;
            } else if (type === 'month') {
                return `${val}月`;
            }else if (type === 'day') {
                return `${val}日`;
            }else if (type === 'hour') {
                return `${val}时`;
            }else if (type === 'minute') {
                return `${val}分`;
            }
            return val;
        },
        // UseTimeConfirm(time){
           
        //     this.TestValue.LA_Time = time.toLocaleDateString()
        //     this.$emit('ModelChange',this.TestValue)
        //     this.UseTime = false;
        // },
        onConfirm(row){
            this.TestValue.LA_IName = row.I_Name
            this.TestValue.LA_IID = row.I_ID
            this.TestValue.TimeList = {}
            this.$emit('ModelChange',this.TestValue)
            this.LabrIsShow = false;

        },
        onSubmit(){
            this.$router.push({path:'/makeAppointment/laboratoryInstrument',query:{fromPath:this.$route.query.fromPath}})
        },
        NextClick(){
           this.$refs.Form.submit()
        }
    }
}
</script>

<style lang="scss" scoped>
.FormBox {
    height: calc( 100vh - 46px);
    display: flex;
    flex-direction: column;
    .van-form {
        flex: 1;
        // background: #fff;
        overflow-y: auto;
        padding-bottom: 10px;
    }
    .EventBox {
        padding-top: 10px;
        background: #f2f2f2;
        .FormBoxTitle {
            font-size: 14px;
            background: #fff;
            padding: 8px 14px;
            color: #333;
        }  
    }
    .BoxRow {
        background: #fff;
        margin-top: 8px;
        h4 {
            font-size: 14px;
            color: #333;
            padding: 0px 14px;
            padding-top: 10px;
        }
        /deep/ .van-field {
            padding: 10px 10px;
        }
        /deep/ .van-field__body {
            border: 1px solid #e6e6e6;
            border-radius: 5px;
            padding:5px;
        }
    }
    .dangerBox {
        margin-top: 8px;
        background: #fff;
        text-align: center;
      
        .ImgBox {
            width: 85%;
            margin: 8px 0px;
            max-height: 280px;
           
        }
        h4 {
            font-size: 14px;
            background: #fff;
            padding: 8px 14px;
            color: #333;
            text-align: left;  
        }
    }
}
.InfButtom {
    padding: 10px 14px;
    background-color: #fff;

    .van-button {
        line-height: 38px;
        height: 38px;
    }
}
</style>